<template>
  <ValidationObserver v-slot="{ invalid }">
    <Modal
      class="migrate-participant-modal"
      :fix-header-and-footer="true"
      :close-on-click-outside="false"
      @close="$emit('close')"
    >
      <template v-slot:title>
        <div>
          Continue Study for Participant {{ participant.customerDefinedId }}
        </div>
        <BfAlert
          v-if="showErrorMessage"
          type="error"
          icon="error"
        >
          Migrating participant {{ participant.customerDefinedId }} failed.
          Please <a
            href="mailto:PPMI_EDChelp@indd.org"
            title="Email the SMC for support."
          >contact support</a> for assistance.
        </BfAlert>
      </template>
      <template v-slot:content>
        <section>
          <h3>Participant Details</h3>
          <p>Add the participant's details to continue their participation in the study.</p>
          <BfForm
            :fields="formFields"
            :data="participant"
          />
        </section>
        <section>
          <h3>GDPR</h3>
          <p>If the participant has signed GDPR Consent, document the date signed.</p>
          <BfForm
            :fields="gdprFields"
            :data="participant"
          />
        </section>
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          :disabled="invalid"
          class="submit-button"
          @click="migrateParticipant"
        >
          Submit
        </el-button>
        <el-button
          type="outline"
          @click="$emit('close')"
        >
          Cancel
        </el-button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfForm from '@/components/BfForm/BfForm'
import BfAlert from '@/components/BfAlert/BfAlert'
import { countryOptions } from '@/utils/countries'
import { formatDateForAPI } from '@/utils/date'
import { sexOptions } from '@/utils/sex'
import { logError } from '@/utils/logging'
import { omit } from 'ramda'

import GET_PENDING_PARTICIPANTS_QUERY from '@/graphql/participants/GetPendingParticipantsEDCQuery.graphql'
import MIGRATE_PENDING_PARTICIPANT_MUTATION from '@/graphql/participants/MigratePendingParticipantMutation.graphql'

export default {
  components: {
    BfForm,
    BfAlert,
    Modal
  },
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  data() {
    const birthdateMinimum = new Date()
    const birthdateMaximum = new Date()

    birthdateMinimum.setFullYear(birthdateMinimum.getFullYear() - 100)
    birthdateMaximum.setFullYear(birthdateMaximum.getFullYear() - 30)

    return {
      showErrorMessage: false,
      formFields: [
        {
          id: 'firstName',
          label: 'First Name',
          type: 'text',
          validationRules: 'required'
        },
        {
          id: 'middleName',
          label: 'Middle Name',
          type: 'text',
          optional: true
        },
        {
          id: 'familyName',
          label: 'Last Name',
          type: 'text',
          validationRules: 'required'
        },
        {
          id: 'emailAddress',
          label: 'Email Address',
          type: 'email',
          validationRules: 'email'
        },
        {
          id: 'birthDate',
          label: 'Date of Birth',
          subtext: 'The participant must be at least 30 years old to participate in the study.  ' +
            'Enter the first day of the month if the exact day is unknown.',
          type: 'date',
          validationRules: 'required',
          placeholder: 'Select a Date',
          'disable-before': birthdateMinimum,
          'disable-after': birthdateMaximum
        },
        {
          id: 'sex',
          label: 'Sex',
          type: 'select',
          possibleValues: sexOptions.filter(option => option.value === 'female' || option.value === 'male'),
          validationRules: 'required',
          popperClass: 'migrate-participant-sex'
        },
        {
          id: 'childbearingPotential',
          label: 'Is participant of childbearing potential?',
          type: 'select',
          possibleValues: [
            { label: 'Yes', displayValue: 'Yes', value: true },
            { label: 'No', displayValue: 'No', value: false }
          ],
          validationRules: 'required',
          popperClass: 'childbearing-potential-dropdown',
          visible: false
        },
        {
          id: 'birthCity',
          label: 'City/Municipality of Birth',
          type: 'text',
          optional: true
        },
        {
          id: 'birthCountry',
          label: 'Country of Birth',
          type: 'select',
          possibleValues: countryOptions,
          optional: true
        }
      ],
      gdprFields: [
        {
          id: 'gdprConsentDate',
          label: 'Date GDPR Consent Signed',
          type: 'date',
          placeholder: 'Select a Date',
          'disable-before': '4/3/2020',
          'disable-after': new Date(), // today
          optional: true
        }
      ]
    }
  },
  computed: {
    /**
     * returns the value of a participants sex based on the sex and childbearing potential
     * chosen in the form
     * @returns {string}
     */
    participantSexValue() {
      if (this.participant.sex === 'female') {
        if (this.participant.childbearingPotential) {
          return 'female_of_childbearing_potential'
        }
        return 'female_not_of_childbearing_potential'
      }

      return this.participant.sex
    }
  },
  watch: {
    'participant.sex'(sex) {
      this.watchSexHandler(sex)
    }
  },
  methods: {
    /**
     * Controls the visibility of the childbearingPotential field
     * @param {string} sex
     */
    watchSexHandler(sex) {
      const childbearingPotentialField =
        this.formFields.find(field => field.id === 'childbearingPotential')

      if (sex === 'female') {
        childbearingPotentialField.visible = true
      } else {
        childbearingPotentialField.visible = false
        this.participant.childbearingPotential = ''
      }
    },
    migrateParticipant() {
      this.isLoading = true
      this.showErrorMessage = false
      this.$apollo.mutate({
        mutation: MIGRATE_PENDING_PARTICIPANT_MUTATION,
        variables: {
          ...omit(['childbearingPotential'], this.participant),
          pendingParticipantId: this.participant.id,
          birthDate: formatDateForAPI(this.participant.birthDate),
          sex: this.participantSexValue,
          gdprConsentDate: this.participant.gdprConsentDate ? formatDateForAPI(this.participant.gdprConsentDate) : null
        },
        update: (store, { data: { createLogEntry } }) => {
          // Remove the migrated participant from the list of pending participants
          const data = store.readQuery({
            query: GET_PENDING_PARTICIPANTS_QUERY,
            variables: {
              studyId: this.$route.params.studyId,
              siteId: this.$route.params.siteId
            }
          })
          data.getPendingParticipantsEDC =
            data.getPendingParticipantsEDC.filter(participant => participant.id !== this.participant.id)
          store.writeQuery({
            query: GET_PENDING_PARTICIPANTS_QUERY,
            variables: {
              studyId: this.$route.params.studyId,
              siteId: this.$route.params.siteId
            },
            data
          })
        }
      })
        .then(response => {
          this.$emit('success', response.data.migratePendingParticipant)
        })
        .catch(error => {
          logError(error, 'MigrateParticipantModal.vue migrate participant mutation')
          this.showErrorMessage = true
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="scss">
  .migrate-participant-modal {
    .modal {
      &__title {
        flex-direction: column;
        align-items: flex-start;
      }

      h3 {
        margin: 0 0 .5rem;
        @include text-style('title', 'small', 'medium');
      }

      p {
        margin: .5rem 0 1.5rem;
      }
    }

    .modal__content section {
      padding: 1.5rem 0;
      border-bottom: 1px solid $cortex;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    .bf-form {
      display: flex;
      flex-direction: column;

      .field {
        width: 100%;
      }

      label {
        font-weight: 500;
      }

      .form-field {
        margin-bottom: 1.5rem;
      }

      .field:last-of-type .form-field {
        margin-bottom: 0;
      }
    }
  }
</style>
