<template>
  <ValidationObserver>
    <Modal
      v-loading="isLoading"
      class="conclude-participant-modal"
      :fix-header-and-footer="true"
      :close-on-click-outside="false"
      @close="$emit('close')"
    >
      <template v-slot:title>
        <div>
          Conclude Study for Participant {{ participant.customerDefinedId }}
        </div>
        <BfAlert
          v-if="showErrorMessage"
          icon="error"
          type="error"
        >
          Concluding participant {{ participant.customerDefinedId }} failed.
          Please <a
            href="mailto:PPMI_EDChelp@indd.org"
            title="Email the SMC for support."
          >contact support</a> for assistance.
        </BfAlert>
      </template>
      <template v-slot:content>
        <p>
          Select the participant's reason for concluding their participation in the study. This action cannot be undone.
        </p>
        <CRFRadioGroup
          v-model="reasonSelection"
          :question="reasonQuestion"
        />
        <div
          v-if="showDeathDateInput"
          class="comment"
        >
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <BfDatePicker
              ref="deathDateInput"
              v-model="deathDate"
              :error="errors[0]"
              class="datepicker"
              label="Death Date"
            />
          </ValidationProvider>
        </div>
        <div
          v-if="showComment"
          class="comment"
        >
          <label for="comment">Please specify</label>
          <BfTextArea
            ref="commentInput"
            v-model="comment"
            name="comment"
          />
        </div>
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          :disabled="disableSubmit"
          @click="concludeParticipant"
        >
          Submit
        </el-button>
        <el-button
          type="outline"
          @click="$emit('close')"
        >
          Cancel
        </el-button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfTextArea from '@/components/BfTextArea/BfTextArea'
import CRFRadioGroup from '@/components/CRFRadioGroup/CRFRadioGroup'
import BfAlert from '@/components/BfAlert/BfAlert'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import { logError } from '@/utils/logging'
import GET_PENDING_PARTICIPANTS_QUERY from '@/graphql/participants/GetPendingParticipantsEDCQuery.graphql'
import CONCLUDE_PENDING_PARTICIPANT_MUTATION from '@/graphql/participants/ConcludePendingParticipantMutation.graphql'
import { formatDateForAPI } from '@/utils/date'

export default {
  components: {
    BfTextArea,
    CRFRadioGroup,
    Modal,
    BfAlert,
    BfDatePicker
  },
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      deathDate: null,
      reasonQuestion: {
        options: [
          {
            position: 1,
            value: 'Burden of study procedures (other than travel)',
            displayValue: 'Burden of study procedures (other than travel)'
          },
          {
            position: 2,
            value: 'Declined continuation in the study',
            displayValue: 'Declined continuation in the study'
          },
          {
            position: 3,
            value: 'Death',
            displayValue: 'Death'
          },
          {
            position: 4,
            value: 'Family, care-partner, or social issues',
            displayValue: 'Family, care-partner, or social issues'
          },
          {
            position: 5,
            value: 'Increased PD Disability',
            displayValue: 'Increased PD Disability'
          },
          {
            position: 6,
            value: 'Lost to follow up',
            displayValue: 'Lost to follow up'
          },
          {
            position: 7,
            value: 'Transportation/Travel issues (ex: logistics or travel, moved away from study site)',
            displayValue: 'Transportation/Travel issues (ex: logistics or travel, moved away from study site)'
          },
          {
            position: 8,
            value: 'Other',
            displayValue: 'Other (indicate reason)'
          }
        ]
      },
      reasonSelection: '',
      comment: '',
      concludeOptions: {
        death: 'Death'
      }
    }
  },
  computed: {
    showComment() {
      return this.reasonSelection === 'Other'
    },
    showDeathDateInput () {
      return this.reasonSelection === this.concludeOptions.death
    },
    disableSubmit() {
      return !this.reason
    },
    reason() {
      return this.showComment ? this.comment : this.reasonSelection
    }
  },
  watch: {
    showComment(show) {
      if (show) {
        // The comment field isn't actually rendered yet when the property changes,
        // so we need to wait for the next tick
        this.$nextTick(() => this.scrollToComment('comment'))
      }
    },
    showDeathDateInput(show) {
      if (show) {
        // The comment field isn't actually rendered yet when the property changes,
        // so we need to wait for the next tick
        this.$nextTick(() => this.scrollToComment('deathDate'))
      }
    }
  },
  methods: {
    scrollToComment(type) {
      if (type === 'comment') {
        this.$refs.commentInput.$el.getElementsByTagName('textarea')[0].focus()
        return this.$refs.commentInput.$el.scrollIntoView(true)
      }
      if (type === 'deathDate') {
        this.$refs.deathDateInput.$el.getElementsByTagName('input')[0].focus()
        this.$refs.deathDateInput.$el.scrollIntoView(true)
      }
    },
    concludeParticipant() {
      this.isLoading = true
      this.showErrorMessage = false
      this.$apollo.mutate({
        mutation: CONCLUDE_PENDING_PARTICIPANT_MUTATION,
        variables: {
          pendingParticipantId: this.participant.id,
          reason: this.reason,
          dateOfDeath: this.reason === this.concludeOptions.death ? formatDateForAPI(this.deathDate) : null
        },
        update: (store, { data: { createLogEntry } }) => {
          // Remove the concluded participant from the list of pending participants
          const data = store.readQuery({
            query: GET_PENDING_PARTICIPANTS_QUERY,
            variables: {
              studyId: this.$route.params.studyId,
              siteId: this.$route.params.siteId
            }
          })
          data.getPendingParticipantsEDC =
            data.getPendingParticipantsEDC.filter(participant => participant.id !== this.participant.id)
          store.writeQuery({
            query: GET_PENDING_PARTICIPANTS_QUERY,
            variables: {
              studyId: this.$route.params.studyId,
              siteId: this.$route.params.siteId
            },
            data
          })
        }
      })
        .then(response => {
          this.$emit('success', this.participant)
        })
        .catch(error => {
          logError(error, 'ConcludeParticipantModal.vue conclude participant mutation')
          this.showErrorMessage = true
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="scss">
  .conclude-participant-modal {
    .modal {
      &__title {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .comment {
      margin-top: 1rem;

      label {
        color: $hillcock;
        @include text-style('interface', 'small', 'regular');
      }
    }
  }
</style>
