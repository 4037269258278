<template>
  <div class="pending-participants">
    <PageHeader title="Transitioning Participants">
      <template v-slot:subtitle>
        <el-button
          type="ghost"
          class="back-link"
          @click="navigateToParticipants"
        >
          <SvgIcon
            name="chevron-right"
            class="flip"
          />
          Back to All Participants
        </el-button>
      </template>
    </PageHeader>

    <BfAlert
      v-if="showConcludeSuccessMessage"
      type="success"
      icon="checkmark"
      :closeable="true"
      @dismiss="showConcludeSuccessMessage = false"
    >
      Study concluded for participant {{ participantToMigrate.customerDefinedId }}.
    </BfAlert>
    <BfAlert
      v-if="showMigrateSuccessMessage"
      type="success"
      icon="checkmark"
      :closeable="true"
      @dismiss="showMigrateSuccessMessage = false"
    >
      Study continued for participant {{ participantToMigrate.customerDefinedId }}.
      <a
        @click="navigateToProfile"
      >View Profile</a>.
    </BfAlert>
    <!-- show the SearchBox while loading to minimize layout flash. -->
    <SearchBox
      v-if="isLoading || hasParticipants"
      placeholder="Find a participant"
      @input="searchStringUpdate"
    />
    <EmptyState
      v-if="pendingParticipantsError"
      title="Error Loading Data"
      subtitle="Try refreshing this page"
      image="error"
      :image-above-text="true"
      :display-in-table="true"
    >
      <el-button
        type="primary"
        class="refresh-page-button"
        @click="refreshPage"
      >
        Refresh Page
      </el-button>
    </EmptyState>
    <el-table
      v-else
      class="pending-participants-table"
      :class="{ 'no-results': !hasResults }"
      :data="participantsToDisplay"
      @sort-change="handleSortEvent"
    >
      <el-table-column
        label="Participant ID"
        prop="customerDefinedId"
        :resizable="false"
        sortable="custom"
        :fixed="true"
        width="150"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          {{ row.customerDefinedId }}
        </template>
      </el-table-column>
      <el-table-column
        label="Cohort"
        prop="cohort"
        :resizable="false"
        sortable="custom"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <strong v-if="row.cohortId">
            {{ getCohortName(row.cohortId) }}
          </strong>
        </template>
      </el-table-column>
      <el-table-column
        label="Subgroup"
        prop="subgroup"
        :resizable="false"
        sortable="custom"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          {{ getSubgroupName(row.subgroupId) || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        label="Decision"
        :sortable="false"
        :resizable="false"
        width="224"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
        </template>
        <template v-slot="{ row }">
          <div class="actions">
            <el-button
              type="ghost"
              class="conclude-button ghost--full"
              @click="handleClickConclude(row)"
            >
              Conclude
            </el-button>
            <!-- By using the participant object directly, we save data for that participant in case the modal
            is closed, then reopened for the same participant later. -->
            <el-button
              type="primary"
              class="continue-button"
              @click="handleClickContinue(row)"
            >
              Continue
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="showEmpyState">
      <EmptyState
        v-if="!isLoading"
        title="No participants awaiting a decision"
        image="pending-participants"
        :image-above-text="true"
        :display-in-table="true"
      >
        <el-button
          type="primary"
          @click="navigateToParticipants"
        >
          View All Participants
        </el-button>
      </EmptyState>
      <SkeletonTable v-else />
    </div>

    <Pagination
      v-if="hasParticipants && hasResults"
      :total="filteredParticipants.length"
      :page="pagination.page"
      :per-page="pagination.perPage"
      @pagechanged="handlePageChange"
      @perpage-changed="handlePerPageChange"
    />
    <ConcludeParticipantModal
      v-if="showConcludeParticipantModal"
      :participant="participantToMigrate"
      @close="showConcludeParticipantModal = false"
      @success="showConcludeSuccessNotification"
    />
    <MigrateParticipantModal
      v-if="showMigrateParticipantModal"
      :participant="participantToMigrate"
      @close="showMigrateParticipantModal = false"
      @success="showMigrateSuccessNotification"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import BfAlert from '@/components/BfAlert/BfAlert'
import SearchBox from '@/components/SearchBox/SearchBox'
import EmptyState from '@/components/EmptyState/EmptyState'
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable'
import Caret from '@/components/Caret/Caret'
import Pagination from '@/components/Pagination/Pagination'
import ConcludeParticipantModal from '@/components/ConcludeParticipantModal/ConcludeParticipantModal'
import MigrateParticipantModal from '@/components/MigrateParticipantModal/MigrateParticipantModal'
import GET_PENDING_PARTICIPANTS_QUERY from '@/graphql/participants/GetPendingParticipantsEDCQuery.graphql'
import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import { sortableCustomerDefinedId } from '@/utils/participant'
import { getAllUniqueSubgroups, getCohortNameById, getSubgroupNameById } from '@/utils/cohorts'
import { ascend, pathOr, prop, reverse, sort } from 'ramda'
import { logError } from '@/utils/logging'

import participantImage from '@/assets/images/emptystate-participants.svg'

export default {
  components: {
    PageHeader,
    BfAlert,
    SearchBox,
    EmptyState,
    SkeletonTable,
    Caret,
    Pagination,
    ConcludeParticipantModal,
    MigrateParticipantModal
  },

  data () {
    return {
      participantImage,
      pendingParticipants: [],
      pendingParticipantsError: false,
      notifications: [],
      cohorts: [],
      searchString: '',
      pagination: {
        page: 1,
        perPage: 10
      },
      sortBy: {
        column: '',
        order: null
      },
      showConcludeParticipantModal: false,
      showConcludeSuccessMessage: false,
      showMigrateParticipantModal: false,
      showMigrateSuccessMessage: false,
      participantToMigrate: {},
      showLoadingState: false
    }
  },

  apollo: {
    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        update: data => data.getStudyCohorts,
        variables: {
          studyId: this.$route.params.studyId
        },
        error (error) {
          logError(error, 'PendingParticipants.vue cohorts query')
        }
      }
    },

    pendingParticipants() {
      return {
        query: GET_PENDING_PARTICIPANTS_QUERY,
        variables: {
          studyId: this.$route.params.studyId,
          siteId: this.$route.params.siteId
        },
        update: data => data.getPendingParticipantsEDC,
        error (error) {
          logError(error, 'PendingParticipants.vue pending participants query')
          this.pendingParticipantsError = true
        }
      }
    }
  },

  computed: {
    /*
     * Filter the list of participants by the search string (if provided)
     */
    filteredParticipants() {
      return this.pendingParticipants
        .filter(participant => {
          // check the customerDefinedId
          return participant.customerDefinedId.includes(this.searchString)
        })
    },

    /*
     * Sort the participants by active sort criteria (column/prop & direction)
     */
    sortedParticipants() {
      if (this.sortBy.column) {
        // handle special cases with custom sorting functions.
        let sortingFunction
        if (this.sortBy.column === 'cohort') {
          sortingFunction = ascend(a => this.getCohortName(a.cohortId))
        } else if (this.sortBy.column === 'subgroup') {
          // set empty values to "zzz" to send to the bottom of the list when sorting
          sortingFunction = ascend(a => this.getSubgroupName(a.subgroupId) || 'zzz')
        } else if (this.sortBy.column === 'customerDefinedId') {
          sortingFunction = sortableCustomerDefinedId
        } else {
          sortingFunction = prop(this.sortBy.column)
        }
        // Now, sort based on the defined sorting function and direction
        const sortedParticipants = sort(
          sortingFunction,
          this.filteredParticipants)

        return this.sortBy.order === 'ascending'
          ? sortedParticipants
          : reverse(sortedParticipants)
      }

      return this.filteredParticipants
    },

    /*
     * Determine the participants to display within the table based on pagination values
     */
    participantsToDisplay() {
      // pagination calculation
      const begin = this.pagination.perPage * (this.pagination.page - 1)
      const end = this.pagination.perPage * this.pagination.page
      return this.sortedParticipants.slice(begin, end)
    },

    isLoading() {
      return pathOr(false, ['queries', 'pendingParticipants', 'loading'], this.$apollo)
    },

    hasParticipants() {
      return this.pendingParticipants.length > 0
    },

    hasResults() {
      return this.filteredParticipants.length > 0
    },

    showEmpyState() {
      return !this.pendingParticipantsError && !this.hasResults
    },

    // Sets up unique subgroups as a computed prop rather than recalculating in each row.
    subgroups() {
      return getAllUniqueSubgroups(this.cohorts)
    }
  },

  methods: {
    /**
     * Resets notification messages
     */
    resetNotifications() {
      this.showConcludeSuccessMessage = false
      this.showMigrateSuccessMessage = false
    },

    /**
     * Handles click event for conclude button
     * @param {Object} row
     */
    handleClickConclude(row) {
      this.resetNotifications()
      this.participantToMigrate = row
      this.showConcludeParticipantModal = true
    },

    /**
     * Handles click event for continue button
     * @param {Object} row
     */
    handleClickContinue(row) {
      this.resetNotifications()
      this.participantToMigrate = row
      this.showMigrateParticipantModal = true
    },

    refreshPage() {
      window.location.reload()
    },

    getCohortName(cohortId) {
      return getCohortNameById(cohortId, this.cohorts)
    },

    /**
     * Get the name of the subgroup associated with the participant.
     * @param subgroupId - the id of the subgroup to search for
     * @returns {string} the name of the searched for subgroup or an emdash if no subgroup was found.
     */
    getSubgroupName(subgroupId) {
      return getSubgroupNameById(subgroupId, this.subgroups)
    },

    /*
     * Update the search string and reset to the first page.
     */
    searchStringUpdate(event) {
      this.searchString = event
      // reset to page 1
      this.pagination.page = 1
    },

    /*
     * React to sorting events on the Element UI table.
     * Update our stored sorting variables with changes.
     */
    handleSortEvent({ column, prop: property, order }) {
      if (!order) {
        // if order is null, reset to default
        this.sortBy.column = ''
      } else if (this.sortBy.column !== property) {
        // update column to the currently selected column for sorting
        this.sortBy.column = property
      }
      this.sortBy.order = order
    },

    /*
     * When a page is changed, update our query
     */
    handlePageChange(newPage) {
      this.pagination.page = newPage
    },

    /*
     * When the perPage value is modified, update our query
     */
    handlePerPageChange(newPerPage) {
      this.pagination.perPage = newPerPage
      this.handlePageChange(1) // reset to page 1
    },

    navigateToParticipants() {
      this.$router.push({
        name: 'EDCHome'
      })
    },

    navigateToProfile() {
      this.$router.push({
        name: 'visitSchedule',
        params: {
          ...this.$route.params,
          participantId: this.participantToMigrate.id
        }
      })
    },

    showConcludeSuccessNotification() {
      this.showMigrateSuccessMessage = false
      this.showConcludeParticipantModal = false
      this.showConcludeSuccessMessage = true
    },

    showMigrateSuccessNotification(migratedParticipant) {
      this.showConcludeSuccessMessage = false
      this.showMigrateParticipantModal = false

      /**
       * set participantToMigrate to the participant we just migrated
       * so we navigate to the correct participantID when using navigateToProfile
       */
      this.participantToMigrate = migratedParticipant
      this.showMigrateSuccessMessage = true
    }

  }
}
</script>

<style lang="scss">
.pending-participants {

  .header {
    margin-bottom: 1rem;

    &__subtitle a {
      display: inline-flex;
      align-items: center;
      margin-left: -1rem;
    }
  }

  .search-box {
    margin-top: 1rem;
  }

  .pending-participants-table {
    border: 1px solid $axon;
    border-top: none;

    &.no-results {
      border-bottom: none;

      .el-table__body-wrapper {
        display: none;
      }
    }

    .actions {
      display: flex;
      align-items: center;
    }
  }
}

// Button overrides
#app .pending-participants-table {
  td {
    vertical-align: middle;

    &:nth-of-type(3) {
      padding: .5rem 0;
    }
  }

  .el-button {
    min-width: initial;

    &+ .el-button {
      margin-left: .5rem;
    }
  }
}
</style>
